import { Component } from "react";
import { Form, InputGroup, Tabs, Tab, Card } from "react-bootstrap";
import Pillstab from "../../components/PillsTab";
import { AJUSTEMENT_INITIAL, NBRE_COMPRIMES } from "../../data/soliphen";
import translator from "../../locales/I18n";

class AjustementPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nb_cp_15_matin: "0", nb_cp_15_soir: "0",
            nb_cp_60_matin: "0", nb_cp_60_soir: "0",
            nb_cp_120_matin: "0", nb_cp_120_soir: "0",
            doseJournaliereActuelleCalculee: "0",
            doseJournaliereActuelleSaisie: "0",
            activeTab: "dose",
            ajustement: AJUSTEMENT_INITIAL,
            disabledBtnDose: true,
            disabledBtnComprimes: true,
            activeResultDose: false,
            activeResultComprimes: false,
            openPopup: false
        };
    }

    componentDidUpdate() {
        translator.locale = this.props.lang;
    }

    setUserChoices = (event, target = null) => {
        if (target === null) {
            target = event.currentTarget;
        }

        const { name, value } = target;

        const inputDoseJournaliere = document.querySelector('input[name="doseJournaliereActuelleSaisie"]');
        const inputAjustement = document.querySelector('input[name="ajustement"]');

        // Prevent Chrome from reseting the input value to 0 when the value is not a number (i.e. when the user types a point instead of a comma)
        if (inputDoseJournaliere.value !== "") {
            inputDoseJournaliere.value = Math.round(inputDoseJournaliere.value);
        }
        if (inputAjustement.value !== "") {
            inputAjustement.value = Math.round(inputAjustement.value);
        }
        if (inputDoseJournaliere && inputAjustement && inputAjustement.value <= 50 && inputAjustement.value >= -50 && inputDoseJournaliere.value !== "" && inputDoseJournaliere.value > 0 && inputAjustement.value !== "") {
            this.setState({ disabledBtnDose: false });
        } else {
            this.setState({ disabledBtnDose: true, activeResultDose: false });
        }

        // Soliphen® 15mg
        const inputNbCp15Matin = document.querySelector('input[name="nb_cp_15_matin"]');
        const inputNbCp15Soir = document.querySelector('input[name="nb_cp_15_soir"]');

        // Soliphen® 60mg
        const inputNbCp60Matin = document.querySelector('input[name="nb_cp_60_matin"]');
        const inputNbCp60Soir = document.querySelector('input[name="nb_cp_60_soir"]');

        // Soliphen® 120mg
        const inputNbCp120Matin = document.querySelector('input[name="nb_cp_120_matin"]');
        const inputNbCp120Soir = document.querySelector('input[name="nb_cp_120_soir"]');

        if ((inputNbCp15Matin.value !== "" || inputNbCp15Soir.value !== "" || inputNbCp60Matin.value !== "" || inputNbCp60Soir.value !== "" || inputNbCp120Matin.value !== "" || inputNbCp120Soir.value !== "")
            && (inputNbCp15Matin.value >= 0 && inputNbCp15Soir.value >= 0 && inputNbCp60Matin.value >= 0 && inputNbCp60Soir.value >= 0 && inputNbCp120Matin.value >= 0 && inputNbCp120Soir.value >= 0)) {
            this.setState({ disabledBtnComprimes: false });
        } else {
            this.setState({ disabledBtnComprimes: true, activeResultComprimes: false });
        }

        this.setState((state) => {
            const newState = { ...state };
            newState[name] = value;

            if (isNaN(newState.nb_cp_15_matin) || newState.nb_cp_15_matin === "") {
                newState.nb_cp_15_matin = "0";
            }
            if (isNaN(newState.nb_cp_15_soir) || newState.nb_cp_15_soir === "") {
                newState.nb_cp_15_soir = "0";
            }
            if (isNaN(newState.nb_cp_60_matin) || newState.nb_cp_60_matin === "") {
                newState.nb_cp_60_matin = "0";
            }
            if (isNaN(newState.nb_cp_60_soir) || newState.nb_cp_60_soir === "") {
                newState.nb_cp_60_soir = "0";
            }
            if (isNaN(newState.nb_cp_120_matin) || newState.nb_cp_120_matin === "") {
                newState.nb_cp_120_matin = "0";
            }
            if (isNaN(newState.nb_cp_120_soir) || newState.nb_cp_120_soir === "") {
                newState.nb_cp_120_soir = "0";
            }

            const doseJournaliereActuelleCalculee =
                (parseFloat(newState.nb_cp_15_matin) + parseFloat(newState.nb_cp_15_soir)) * 15 +
                (parseFloat(newState.nb_cp_60_matin) + parseFloat(newState.nb_cp_60_soir)) * 60 +
                (parseFloat(newState.nb_cp_120_matin) + parseFloat(newState.nb_cp_120_soir)) * 120
                ;

            newState.doseJournaliereActuelleCalculee = doseJournaliereActuelleCalculee;
            newState.doseJournaliereModifieeSaisie = parseFloat(Math.round(newState.doseJournaliereActuelleSaisie)) * (1 + (newState.ajustement / 100))
            newState.doseJournaliereModifieeCalculee = doseJournaliereActuelleCalculee * (1 + (Math.round(newState.ajustement) / 100))
            newState.doseJournaliereModifieeActive = newState.activeTab === "dose" ? Math.round(newState.doseJournaliereModifieeSaisie) : newState.doseJournaliereModifieeCalculee;

            const doseJMA = newState.doseJournaliereModifieeActive;
            newState.currentDose = NBRE_COMPRIMES.find(
                ({ doseJournaliere: { min, max } }) =>
                    min < doseJMA && max >= doseJMA
            );

            return newState;
        });

    };

    downNumberInput = (event) => {
        const inputName = event.currentTarget.dataset.incrementInput;
        let number = event.currentTarget.dataset.incrementNumber;
        let numberRef = 0;

        if (inputName && number) {
            let input = document.querySelector('input[name="' + inputName + '"]');
            const inputValue = parseInt(input.value);

            if (event.currentTarget.dataset.incrementNumberMin) {
                numberRef = event.currentTarget.dataset.incrementNumberMin;

                if (Math.abs(numberRef - inputValue) < number) {
                    number = Math.abs(numberRef - inputValue);
                }
            }

            if (inputValue > parseInt(numberRef)) {
                input.value = inputValue - parseInt(number);
                this.setUserChoices(null, input);
                this.addPlusBeforeNumber();
            }
        }
    }

    upNumberInput = (event) => {
        const inputName = event.currentTarget.dataset.incrementInput;
        let number = event.currentTarget.dataset.incrementNumber;
        let numberRef = 0;

        if (inputName && number) {
            let input = document.querySelector('input[name="' + inputName + '"]');
            const inputValue = parseInt(input.value);

            if (event.currentTarget.dataset.incrementNumberMax) {
                numberRef = event.currentTarget.dataset.incrementNumberMax;

                if ((numberRef - inputValue) < number) {
                    number = numberRef - inputValue;
                }

                if (inputValue < numberRef) {
                    input.value = inputValue + parseInt(number);
                    this.setUserChoices(null, input);
                    this.addPlusBeforeNumber();
                }
            } else {
                input.value = inputValue + parseInt(number);
                this.setUserChoices(null, input);
                this.addPlusBeforeNumber();
            }
        }
    }

    addPlusBeforeNumber = () => {
        const input = document.querySelector('input[name="ajustement"]');
        const span = document.querySelector('.ajustement-input-before');

        if (input.value > 0) {
            span.classList.remove('hidden');
            input.style.width = input.value.length + "ch";
        } else {
            span.classList.add('hidden');
            input.style.width = "3ch";
        }
    }

    render() {
        const { doseJournaliereActuelleCalculee, doseJournaliereModifieeSaisie, doseJournaliereModifieeCalculee, doseJournaliereModifieeActive, currentDose, disabledBtnDose, disabledBtnComprimes, activeResultDose, activeResultComprimes, activeTab, openPopup } = this.state;

        let disabledAttr = '';

        if ((disabledBtnDose && activeTab === "dose") || (disabledBtnComprimes && activeTab === "comprimes")) {
            disabledAttr = 'disabled';
        }

        return (
            <div className="flex flex-wrap">
                <div
                    className={`md:hidden w-full h-full bg-black-35 backdrop-blur-lg fixed top-0 left-0 z-10 translate-x-full [&.open]:translate-x-0  ${openPopup ? "open" : ""}`}
                    onClick={() => { this.setState({ openPopup: false }) }}
                ></div>
                <div className={`text-black-75 fixed top-0 right-0 h-full md:h-auto z-20 translate-x-full [&.open]:translate-x-0 [&.open]:shadow-popup md:translate-x-0 transition-transform md:relative w-[335px] md:w-1/3 lg:w-[265px] pt-20 md:pt-0 px-5 md:px-0 bg-white-100 font-karia ${openPopup ? "open" : ""}`}>
                    <span dangerouslySetInnerHTML={{ __html: translator.t("adjustPage.paragraph1") }} />
                    <br />
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: translator.t("adjustPage.paragraph2") }} />
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: translator.t("adjustPage.paragraph3") }} />
                    <br />
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: translator.t("adjustPage.sideEffects") }} />
                    <br />

                    <button
                        className="py-2 px-5 text-sm rounded-full border border-black-100 font-medium mt-8 md:hidden"
                        onClick={() => { this.setState({ openPopup: false }) }}
                    >{translator.t("buttons.understood")}</button>
                    <button
                        className="absolute block md:hidden top-5 right-5 w-8 h-8"
                        onClick={() => { this.setState({ openPopup: false }) }}
                    >
                        <img src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI1LDI2Yy0wLjI1NiwwLTAuNTEyLTAuMDk4LTAuNzA3LTAuMjkzTDE2LDE3LjQxNGwtOC4yOTMsOC4yOTNjLTAuMzkxLDAuMzkxLTEuMDIzLDAuMzkxLTEuNDE0LDAJcy0wLjM5MS0xLjAyMywwLTEuNDE0TDE0LjU4NiwxNkw2LjI5Myw3LjcwN2MtMC4zOTEtMC4zOTEtMC4zOTEtMS4wMjMsMC0xLjQxNHMxLjAyMy0wLjM5MSwxLjQxNCwwTDE2LDE0LjU4Nmw4LjI5My04LjI5MwljMC4zOTEtMC4zOTEsMS4wMjMtMC4zOTEsMS40MTQsMHMwLjM5MSwxLjAyMywwLDEuNDE0TDE3LjQxNCwxNmw4LjI5Myw4LjI5M2MwLjM5MSwwLjM5MSwwLjM5MSwxLjAyMywwLDEuNDE0CUMyNS41MTIsMjUuOTAyLDI1LjI1NiwyNiwyNSwyNnoiIGZpbGw9IiMwODA4MDgiLz48L3N2Zz4=' alt="" />
                    </button>
                </div>
                <div className="w-full md:w-2/3 md:max-w-auto lg:max-w-[570px] md:pl-10">
                    <Card className="bg-white-ghost !border-0 !rounded-none w-full">
                        <Card.Body bsPrefix="card-body">
                            <Tabs
                                defaultActiveKey="dose"
                                transition={true}
                                id="ajustement-tab"
                                activeKey={this.activeTab}
                                onSelect={(k) => {
                                    document.querySelector('input[name="ajustement"]').value = "20";
                                    let res = undefined;
                                    if (k === "dose") {
                                        res = doseJournaliereModifieeSaisie
                                    } else {
                                        res = doseJournaliereModifieeCalculee
                                    }
                                    this.setState({ activeTab: k, doseJournaliereModifieeActive: res })
                                }}
                                className="grid gap-x-2 grid-cols-2 p-2.5"
                            >
                                <Tab bsPrefix="tab-pane hidden opacity-0 [&.show]:opacity-100 [&.active]:block transition-opacity ease-linear" eventKey="dose" title={translator.t("adjustPage.dailyDose.title")} tabClassName="w-full md:py-4 px-2 m-0 min-h-[70px] md:min-h-auto font-karia !text-base !text-white-100 !bg-blue-dark-30 !border-0 [&.active]:!bg-blue-dark-100 rounded-sm relative after:absolute after:left-1/2 after:-bottom-[7px] after:-translate-x-1/2 after:w-0 after:h-0 after:border-[10px] after:border-t-[7px] after:border-b-0 after:border-transparent after:border-t-blue-dark-30 [&.active]:after:border-t-blue-dark-100">
                                    <Form.Group className="bg-white-ghost py-8 text-center text-black-100 flex flex-col items-center relative before:w-0 before:h-0 before:border-[25px] before:border-t-[10px] before:border-b-0 before:border-transparent before:border-t-white-ghost">
                                        <Form.Label className="font-medium text-xl">{translator.t("adjustPage.dailyDose.actualDose1")}<br />{translator.t("adjustPage.dailyDose.actualDose2")}</Form.Label>
                                        <div className="relative mt-3 grid grid-cols-[2fr_1fr_1fr_2fr]">
                                            <div className="overflow-visible col-start-2 relative before:block before:h-[2px] before:w-[50px] before:absolute before:-bottom-[6px] before:right-0 before:bg-black-100">
                                                <Form.Control
                                                    size='lg'
                                                    type="number"
                                                    min="0"
                                                    // step="1"
                                                    pattern="\d*"
                                                    name="doseJournaliereActuelleSaisie"
                                                    onChange={this.setUserChoices}
                                                    placeholder='0'
                                                    className="appearance-none w-full text-right bg-transparent text-black-100 text-3xl font-medium outline-none placeholder:text-black-15"
                                                />
                                            </div>
                                            <span className="ml-2 text-black-100 font-normal text-left flex items-end">{translator.t("adjustPage.dailyDose.unit")}</span>
                                        </div>
                                    </Form.Group>
                                </Tab>
                                <Tab bsPrefix="tab-pane hidden opacity-0 [&.show]:opacity-100 [&.active]:block transition-opacity ease-linear" eventKey="comprimes" title={translator.t("adjustPage.pillCounts.title")} tabClassName="w-full md:py-4 px-2 min-h-[70px] md:min-h-auto font-karia !text-base !text-white-100 !bg-blue-dark-30 !border-0 [&.active]:!bg-blue-dark-100 rounded-sm relative after:absolute after:left-1/2 after:-bottom-[7px] after:-translate-x-1/2 after:w-0 after:h-0 after:border-[10px] after:border-t-[7px] after:border-b-0 after:border-transparent after:border-t-blue-dark-30 [&.active]:after:border-t-blue-dark-100">
                                    <Form.Group className="flex flex-col items-center py-8 px-5">
                                        <Form.Label className="text-center text-xl block font-medium mb-5">{translator.t("adjustPage.pillCounts.actualCounts1")}<br />{translator.t("adjustPage.pillCounts.actualCounts2")}</Form.Label>
                                        <div className="flex justify-end p-1 w-full max-w-[370px] mb-1 mx-auto">
                                            <span className="w-[80px] font-karia font-semibold text-sm text-center">{translator.t("pillsTable.morning")}</span>
                                            <span className="w-[80px] font-karia font-semibold text-sm text-center">{translator.t("pillsTable.evening")}</span>
                                        </div>
                                        <InputGroup className="bg-yellow-100 flex justify-center p-1 w-[370px] mb-1 max-w-full">
                                            <InputGroup.Text className="grow pr-2">
                                                <a
                                                    className="block py-6 pl-5 font-medium underline underline-offset-4 text-[#0000EE] hover:text-blue-light"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={translator.t("links.soliphen15")}
                                                >
                                                    Soliphen® 15mg
                                                </a>
                                            </InputGroup.Text>
                                            <Form.Control
                                                size='text'
                                                type="number"
                                                step="0.5"
                                                min="0"
                                                placeholder="0"
                                                name="nb_cp_15_matin"
                                                onChange={this.setUserChoices}
                                                className="w-[80px] text-3xl font-medium text-center border-r border-black-8 placeholder:text-black-15"
                                            />

                                            <Form.Control
                                                size='text'
                                                type="number"
                                                step="0.5"
                                                min="0"
                                                placeholder="0"
                                                name="nb_cp_15_soir"
                                                onChange={this.setUserChoices}
                                                className="w-[80px] text-3xl font-medium text-center placeholder:text-black-15" />
                                        </InputGroup>
                                        <InputGroup className="bg-yellow-100 flex justify-center p-1 w-[370px] mb-1 max-w-full">
                                            <InputGroup.Text className="grow pr-2">
                                                <a
                                                    className="block py-6 pl-5 font-medium underline underline-offset-4 text-[#0000EE] hover:text-blue-light"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={translator.t("links.soliphen60")}
                                                >
                                                    Soliphen® 60mg
                                                </a>
                                            </InputGroup.Text>
                                            <Form.Control
                                                size='text'
                                                type="number"
                                                step="0.25"
                                                min="0"
                                                placeholder="0"
                                                name="nb_cp_60_matin"
                                                onChange={this.setUserChoices}
                                                className="w-[80px] text-3xl font-medium text-center border-r border-black-8 placeholder:text-black-15" />
                                            <Form.Control
                                                size='text'
                                                type="number"
                                                step="0.25"
                                                min="0"
                                                name="nb_cp_60_soir"
                                                placeholder="0"
                                                onChange={this.setUserChoices}
                                                className="w-[80px] text-3xl font-medium text-center placeholder:text-black-15" />
                                        </InputGroup>
                                        <InputGroup className="bg-yellow-100 flex justify-center p-1 w-[370px] mb-1 max-w-full">
                                            <InputGroup.Text className="grow pr-2">
                                                <a
                                                    className="block py-6 pl-5 font-medium underline underline-offset-4 text-[#0000EE] hover:text-blue-light"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={translator.t("links.soliphen120")}
                                                >
                                                    Soliphen® 120mg
                                                </a>
                                            </InputGroup.Text>
                                            <Form.Control
                                                size='text'
                                                type="number"
                                                step="0.25"
                                                min="0"
                                                placeholder="0"
                                                name="nb_cp_120_matin"
                                                onChange={this.setUserChoices}
                                                className="w-[80px] text-3xl font-medium text-center border-r border-black-8 placeholder:text-black-15" />
                                            <Form.Control
                                                size='text'
                                                type="number"
                                                step="0.25"
                                                min="0"
                                                placeholder="0"
                                                name="nb_cp_120_soir"
                                                onChange={this.setUserChoices}
                                                className="w-[80px] text-3xl font-medium text-center placeholder:text-black-15" />
                                        </InputGroup>
                                        {activeTab === "comprimes" &&
                                            <button
                                                className="font-karla font-medium bg-blue-light disabled:bg-black-15 disabled:text-black-30 text-white-100 transition-colors p-6 rounded-full w-full max-w-[330px] mt-9 "
                                                onClick={() => {
                                                    if (activeTab === "dose") {
                                                        this.setState({
                                                            activeResultDose: true,
                                                        })
                                                    }

                                                    if (activeTab === "comprimes") {
                                                        this.setState({
                                                            activeResultComprimes: true
                                                        })
                                                    }
                                                }}
                                                disabled={disabledAttr}
                                            >
                                                {translator.t("buttons.calculateActualDose")}
                                            </button>
                                        }
                                    </Form.Group >

                                    <Card className={`bg-blue-dark-100 py-8 px-5 mt-10 text-center text-white-75 flex-col items-center relative before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:w-0 before:h-0 before:border-[25px] before:border-t-[10px] before:border-b-0 before:border-transparent before:border-t-white-ghost hidden [&.open]:flex ${activeResultDose && activeTab === "dose" ? "open" : ""} ${activeResultComprimes && activeTab === "comprimes" ? "open" : ""}`}>
                                        <Card.Body>
                                            <Card.Title className="font-medium">{translator.t("adjustPage.pillCounts.actualResultTitle")}</Card.Title>
                                            <Card.Text className="text-white-100 text-3xl font-medium">
                                                {!isNaN(parseFloat(doseJournaliereActuelleCalculee)) ? parseFloat(doseJournaliereActuelleCalculee) : "0"} mg
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Tab>
                            </Tabs>
                            <Form.Group className={`py-8 px-5 text-center flex flex-col items-center relative ${activeTab === "comprimes" ? "md:mx-5 bg-white-100 before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:w-0 before:h-0 before:border-[25px] before:border-t-[10px] before:border-b-0 before:border-transparent before:border-t-blue-dark-100" : ""}  hidden [&.open]:block ${activeTab === "dose" ? "open" : ""} ${activeResultComprimes && activeTab === "comprimes" ? "open" : ""}`}>
                                <Form.Label className="font-medium text-xl">{translator.t("adjustPage.dailyDose.increaseOrDecrease1")}<br />{translator.t("adjustPage.dailyDose.increaseOrDecrease2")}</Form.Label>
                                <div className="w-full relative grid grid-cols-[2fr_1fr_1fr_2fr] mt-3">
                                    <button
                                        data-increment-input="ajustement"
                                        data-increment-number="5"
                                        data-increment-number-min="-50"
                                        className="group/btn cursor-pointer flex justify-end text-3xl font-semibold pr-4 items-center"
                                        onClick={this.downNumberInput}
                                    >
                                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" width="20" height="20" className="fill-black-100 group-hover/btn:fill-blue-light transition-colors"><path d="m52.6 69.8l30-36c1-1.2 1.5-2.8 1.4-4.3-0.2-1.6-0.9-3.1-2.2-4.1-1.2-1-2.8-1.5-4.3-1.4-1.6 0.2-3.1 0.9-4.1 2.2l-25.4 30.4-25.4-30.4c-1-1.3-2.5-2-4.1-2.2-1.5-0.1-3.1 0.4-4.3 1.4-1.3 1-2 2.5-2.2 4.1-0.1 1.5 0.4 3.1 1.4 4.3l30 36q0.8 1.1 2.1 1.6 1.2 0.6 2.5 0.6 1.3 0 2.5-0.6 1.3-0.5 2.1-1.6z" /></svg>
                                    </button>
                                    <div className="overflow-visible col-start-2 relative flex justify-end items-center before:block before:h-[2px] before:w-[50px] before:absolute before:-bottom-[6px] before:right-0 before:bg-black-100">
                                        <span className="ajustement-input-before text-black-100 text-3xl font-medium">+</span>
                                        <Form.Control
                                            size='lg'
                                            type="number"
                                            min="-50"
                                            max="50"
                                            step="5"
                                            pattern="\d*"
                                            name="ajustement"
                                            onChange={(e) => {
                                                this.setUserChoices(e);
                                                this.addPlusBeforeNumber();
                                            }}
                                            defaultValue={AJUSTEMENT_INITIAL}
                                            placeholder={AJUSTEMENT_INITIAL}
                                            className="appearance-none text-right bg-transparent text-black-100 text-3xl font-medium outline-none placeholder:text-black-15 w-[2ch]"
                                        />
                                    </div>
                                    <span className="ml-2 text-black-100 font-normal text-left flex items-end pb-1">%</span>
                                    <button
                                        data-increment-input="ajustement"
                                        data-increment-number="5"
                                        data-increment-number-max="50"
                                        className="group/btn cursor-pointer flex items-center text-3xl font-semibold pl-0"
                                        onClick={this.upNumberInput}
                                    >
                                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" width="20" height="20" className="fill-black-100 group-hover/btn:fill-blue-light transition-colors"><path d="m52.6 26.2l30 36c1 1.2 1.5 2.8 1.4 4.3-0.2 1.6-0.9 3.1-2.2 4.1-1.2 1-2.8 1.5-4.3 1.4-1.6-0.2-3.1-0.9-4.1-2.2l-25.4-30.4-25.4 30.4c-1 1.3-2.5 2-4.1 2.2-1.5 0.1-3.1-0.4-4.3-1.4-1.3-1-2-2.5-2.2-4.1-0.1-1.5 0.4-3.1 1.4-4.3l30-36q0.8-1.1 2.1-1.6 1.2-0.6 2.5-0.6 1.3 0 2.5 0.6 1.3 0.5 2.1 1.6z" /></svg>
                                    </button>
                                </div>
                                <button
                                    className="flex items-center px-3 py-2 mt-7 self-start font-karia md:hidden"
                                    onClick={() => { this.setState({ openPopup: true }) }}
                                >
                                    <img className="w-[20px] mr-2" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTYsMEMyLjcsMCwwLDIuNywwLDZzMi43LDYsNiw2czYtMi43LDYtNlM5LjMsMCw2LDB6IE02LDExYy0yLjgsMC01LTIuMi01LTVzMi4yLTUsNS01czUsMi4yLDUsNVM4LjgsMTEsNiwxMXoiLz48cGF0aCBkPSJNNiA0LjhjLS4zIDAtLjUuMi0uNS41djMuNEM1LjUgOSA1LjcgOS4yIDYgOS4yYy4zIDAgLjUtLjIuNS0uNVY1LjNDNi41IDUuMSA2LjMgNC44IDYgNC44ek02IDIuOGMtLjMgMC0uNS4yLS41LjUgMCAwIDAgLjEgMCAuMSAwIC4zLjIuNS41LjVoMGMuMyAwIC41LS4yLjUtLjUgMCAwIDAtLjEgMC0uMUM2LjUgMyA2LjMgMi44IDYgMi44eiIvPjwvc3ZnPg==" alt="" />
                                    {translator.t("adjustPage.learnMore")}
                                </button>
                                {activeTab === "dose" &&
                                    <button
                                        className="font-karla font-medium bg-blue-light disabled:bg-black-15 disabled:text-black-30 text-white-100 transition-colors p-6 rounded-full w-full max-w-[330px] mt-9 "
                                        onClick={() => {
                                            if (activeTab === "dose") {
                                                this.setState({
                                                    activeResultDose: true,
                                                })
                                            }

                                            if (activeTab === "comprimes") {
                                                this.setState({
                                                    activeResultComprimes: true
                                                })
                                            }
                                        }}
                                        disabled={disabledAttr}
                                    >
                                        {translator.t("buttons.calculateDoseAdjust")}
                                    </button>
                                }
                            </Form.Group>
                        </Card.Body>
                    </Card>
                    <Card className={`bg-white-ghost pb-5 hidden [&.open]:block ${activeResultDose && activeTab === "dose" ? "open" : ""} ${activeResultComprimes && activeTab === "comprimes" ? "open" : ""}`}>
                        <Card.Header className="bg-blue-light py-8 px-5 text-center text-white-75 flex flex-col items-center relative before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:w-0 before:h-0 before:border-[25px] before:border-t-[10px] before:border-b-0 before:border-transparent before:border-t-white-100">
                            <Card.Title className="font-medium">{translator.t("adjustPage.responseCard.doseTitle")}</Card.Title>
                            <Card.Text className="text-white-100 text-3xl font-medium mt-3">
                                {/* Saisie : {doseJournaliereModifieeSaisie}{" "}mg
                                Calculée : {doseJournaliereModifieeCalculee}{" "}mg */}
                                {!isNaN(Math.round(doseJournaliereModifieeActive * 10) / 10) ? Math.round(doseJournaliereModifieeActive * 10) / 10 : "0"} mg
                            </Card.Text>
                        </Card.Header>
                        <Card.Body className="md:mx-5 bg-white-100 py-9 px-5 relative before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:w-0 before:h-0 before:border-[25px] before:border-t-[10px] before:border-b-0 before:border-transparent before:border-t-blue-light">
                            <Card.Title className="font-medium text-center mb-5">{translator.t("adjustPage.responseCard.pillsTitle")}</Card.Title>
                            <Pillstab currentDose={currentDose} lang={this.props.lang}></Pillstab>
                        </Card.Body>
                    </Card>
                </div>
            </div >
        );
    }
}

export default AjustementPage;