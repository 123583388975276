import { Component } from "react";

class HomePage extends Component {
    // unused page since refactoring by ux/ui team

    render() {
        return (
            <div style={{ textAlign: 'left', margin: '1em' }}>
                <h4>
                    Indications d’utilisation
                </h4>
                <p>
                    Chez les chiens : Prévention des crises d’épilepsie généralisée.
                </p>
                <h4>
                    Principes actifs / Molécule :
                </h4>
                Phénobarbital
                <h4>
                    Forme pharmaceutique :
                </h4>
                Comprimé
                <h4>
                    Inscription au tableau des substances vénéneuses (Liste I / II).
                </h4>
                <h4>
                    Classement du médicament en matière de délivrance :
                </h4>
                Usage vétérinaire<br />
                Liste II<br />
                À ne délivrer que sur ordonnance.<br />
                Respecter les doses prescrites.
            </div>
        );
    }
}

export default HomePage;