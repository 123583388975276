import { Component } from "react";
import translator from "../../locales/I18n";

class Pillstab extends Component {
  componentDidUpdate() {
    translator.locale = this.props.lang;
  }

  render() {
    const { currentDose } = this.props;

    return (
      <div className="flex flex-col items-center">
        <div className="flex justify-end p-1 w-full max-w-[370px] mb-1 mx-auto">
          <span className="w-[80px] font-karia font-semibold text-sm text-center">
            {translator.t("pillsTable.morning")}
          </span>
          <span className="w-[80px] font-karia font-semibold text-sm text-center">
            {translator.t("pillsTable.evening")}
          </span>
        </div>
        <div className="bg-yellow-100 flex p-1 w-full max-w-[370px] mb-1 mx-auto">
          <a
            className="App-link py-6 pl-5 pr-2 font-medium grow underline underline-offset-4 text-[#0000EE] hover:text-blue-light"
            target="_blank"
            rel="noreferrer"
            href={translator.t("links.soliphen15")}
          >
            Soliphen® 15mg
          </a>
          <div
            className={`w-[80px] flex justify-center items-center font-medium text-3xl border-r border-black-8 bg-white-100 ${
              currentDose?.soliphen15?.matin ? "" : "text-black-15"
            }`}
          >
            {currentDose?.soliphen15?.matin || "x"}
          </div>
          <div
            className={`w-[80px] flex justify-center items-center font-medium text-3xl border-r border-black-8 bg-white-100 ${
              currentDose?.soliphen15?.soir ? "" : "text-black-15"
            } `}
          >
            {currentDose?.soliphen15?.soir || "x"}
          </div>
        </div>
        <div className="w-full max-w-[370px] pl-2.5 relative before:h-[1px] before:w-full before:bg-black-8 before:absolute before:top-1/2 before:left-0 before:-translate-y-1/2">
          <span className="px-1 bg-white-100 relative">
            {translator.t("pillsTable.or")}
          </span>
        </div>
        <div className="bg-yellow-100 flex p-1 w-full max-w-[370px] mb-1 mx-auto">
          <a
            className="App-link py-6 pl-5 pr-2 font-medium grow underline underline-offset-4 text-[#0000EE] hover:text-blue-light"
            target="_blank"
            rel="noreferrer"
            href={translator.t("links.soliphen60")}
          >
            Soliphen® 60mg
          </a>
          <div
            className={`w-[80px] flex justify-center items-center font-medium text-3xl border-r border-black-8 bg-white-100 ${
              currentDose?.soliphen60?.matin ? "" : "text-black-15"
            }`}
          >
            {currentDose?.soliphen60?.matin || "x"}
          </div>
          <div
            className={`w-[80px] flex justify-center items-center font-medium text-3xl border-r border-black-8 bg-white-100 ${
              currentDose?.soliphen60?.soir ? "" : "text-black-15"
            }`}
          >
            {currentDose?.soliphen60?.soir || "x"}
          </div>
        </div>
        <div className="w-full max-w-[370px] pl-2.5 relative before:h-[1px] before:w-full before:bg-black-8 before:absolute before:top-1/2 before:left-0 before:-translate-y-1/2">
          <span className="px-1 bg-white-100 relative">
            {translator.t("pillsTable.or")}
          </span>
        </div>
        <div className="bg-yellow-100 flex p-1 w-full max-w-[370px] mb-1 mx-auto">
          <a
            className="App-link py-6 pl-5 pr-2 font-medium grow underline underline-offset-4 text-[#0000EE] hover:text-blue-light"
            target="_blank"
            rel="noreferrer"
            href={translator.t("links.soliphen120")}
          >
            Soliphen® 120mg
          </a>
          <div
            className={`w-[80px] flex justify-center items-center font-medium text-3xl border-r border-black-8 bg-white-100 ${
              currentDose?.soliphen120?.matin ? "" : "text-black-15"
            }`}
          >
            {currentDose?.soliphen120?.matin || "x"}
          </div>
          <div
            className={`w-[80px] flex justify-center items-center font-medium text-3xl border-r border-black-8 bg-white-100 ${
              currentDose?.soliphen120?.soir ? "" : "text-black-15"
            }`}
          >
            {currentDose?.soliphen120?.soir || "x"}
          </div>
        </div>
      </div>
    );
  }
}

export default Pillstab;
