import React, { Component } from "react";

// import logo from './logo.svg';
import "./App.scss";
import Header from "../Header";
import HomePage from "../../pages/HomePage";
import InitiationPage from "../../pages/InitiationPage";
import AjustementPage from "../../pages/AjustementPage";
import Nav from "../Nav";

class TvmCalculator extends Component {
  constructor(props) {
    super(props);
    this.changeCurrentScreen = this.changeCurrentScreen.bind(this);
    this.gohome = this.gohome.bind(this);

    this.state = {
      width: props.root.clientWidth,
      currentScreen: "initiation",
      lang: "fr",
    };
  }

  changeCurrentScreen(e) {
    this.setState({ currentScreen: e.target.value });
  }

  gohome() {
    this.setState({ currentScreen: "home" });
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("mode") && urlParams.get("mode") === "iframe") {
      document.body.style.overflow = "hidden";
    }

    const langParam = urlParams.get("lang");
    this.setState({ lang: langParam || "fr" });

    if (typeof ResizeObserver === "undefined") {
      return;
    }

    this.resizeObserver = new ResizeObserver(() => {
      this.setState({ width: this.props.root.clientWidth });

      if (window.top !== window.self) {
        const value = document.documentElement.offsetHeight;

        if (this.prevHeight !== value) {
          this.prevHeight = value;

          window.top.postMessage(
            JSON.stringify({
              type: "heightChange",
              value,
            }),
            "*"
          );
        }
      }
    });
    this.resizeObserver.observe(this.props.root);

    this.prevHeight = document.documentElement.offsetHeight;
    // we do not share sensitive data so the target origin is irrelevant
    window.top.postMessage(
      JSON.stringify({
        type: "heightChange",
        value: this.prevHeight,
      }),
      "*"
    );
  }

  componentWillUnmount() {
    this.resizeObserver?.disconnect();
  }

  render() {
    const { width, currentScreen } = this.state;
    const X = 200;
    const Y = 400;

    return (
      <div
        className={`app ${width < X ? "small" : width < Y ? "medium" : "large"
          } w-full font-rubik max-w-1500 m-auto mt-10 md:mt-15`}
      >
        <Header />
        <Nav onClick={this.changeCurrentScreen} currentScreen={currentScreen} lang={this.state.lang} />
        {currentScreen === "home" && <HomePage />}
        {currentScreen === "initiation" && <InitiationPage changeCurrentScreen={this.changeCurrentScreen} lang={this.state.lang} />}
        {currentScreen === "ajustement" && <AjustementPage lang={this.state.lang} />}
      </div>
    );
  }
}

export default TvmCalculator;
