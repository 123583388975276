import { I18n } from "i18n-js";
import fr from "./fr.json";
import en from "./en.json";
import de from "./de.json";


const translations = {
    fr: fr,
    en: en,
    de: de,
};

// Config i18n-js
const translator = new I18n();
translator.translations = translations;
translator.fallbacks = true;
translator.locale = 'fr';

export default translator;