import React from "react";
import ReactDOM from "react-dom/client";
//import 'bootstrap/dist/css/bootstrap.min.css';

import App from "./components/App";
import reportWebVitals from "./reportWebVitals";

const widgets = document.querySelectorAll(".widget-soliphen");

widgets.forEach((element) => {
  const root = ReactDOM.createRoot(element);
  root.render(
    <React.StrictMode>
      <App root={element} />
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
