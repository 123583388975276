import { Component } from "react";
import { Form, Card } from "react-bootstrap";
import { NBRE_COMPRIMES, DOSE_INITIALE } from "../../data/soliphen";

import Pillstab from "../../components/PillsTab";
import translator from "../../locales/I18n";

class InitiationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dosePerKg: DOSE_INITIALE,
            disabledBtn: true,
            activeResult: false,
            openPopup: false,
            openText: false,
        };
    }

    componentDidUpdate() {
        translator.locale = this.props.lang;
    }

    setUserChoices = (event, target = null) => {
        if (target === null) {
            target = event.currentTarget;
        }

        this.setState({ errorMessageDogWeight: '', errorMessageDosePerKg: '' });
        const { name, value } = target;

        const inputDogWeight = document.querySelector('input[name="dog-weight"]');
        const inputDosePerKg = document.querySelector('input[name="dose-per-kg"]');

        if (!(inputDogWeight.value < 3)) {
            inputDogWeight.value = Math.round(inputDogWeight.value);
        }
        inputDosePerKg.value = Math.round(inputDosePerKg.value);

        if (inputDogWeight && inputDosePerKg && inputDogWeight.value !== "" && inputDosePerKg.value !== "" && inputDogWeight.value >= 3 && inputDogWeight.value <= 80 && inputDosePerKg.value <= 30 && inputDosePerKg.value > 0) {
            this.setState({ disabledBtn: false });
        } else {
            this.setState({ disabledBtn: true, activeResult: false });
            if (inputDogWeight.value < 3 && inputDogWeight.value !== "") {
                this.setState({ errorMessageDogWeight: translator.t("initPage.inputCard.weight.lightWeightError") });
            }
            if (inputDogWeight.value > 80 && inputDogWeight.value !== "") {
                this.setState({ errorMessageDogWeight: translator.t("initPage.inputCard.weight.overWeightError") });
            }
        }

        this.setState((state) => {
            const newState = { ...state };
            if (name === "dog-weight") {
                newState.dogWeight = Math.round(value);
            } else if (name === "dose-per-kg") {
                newState.dosePerKg = Math.round(value);
            }

            const doseJournaliere =
                parseInt(newState.dosePerKg) * parseInt(newState.dogWeight);
            newState.currentDose = NBRE_COMPRIMES.find(
                ({ doseJournaliere: { min, max } }) =>
                    min < doseJournaliere && max >= doseJournaliere
            );

            return newState;
        });
    };

    render() {
        const { dogWeight, dosePerKg, currentDose, disabledBtn, activeResult, openPopup, openText } = this.state;

        let disabledAttr = '';
        if (disabledBtn) {
            disabledAttr = 'disabled';
        }

        let showReferenceP2 = true;
        if (translator.t("initPage.referenceP2", { defaultValue: "missing" }) === "missing") {
            showReferenceP2 = false
        }

        return (
            <div className="flex flex-wrap">
                <div
                    className={`md:hidden w-full h-full bg-black-35 backdrop-blur-lg fixed top-0 left-0 z-10 translate-x-full [&.open]:translate-x-0  ${openPopup ? "open" : ""}`}
                    onClick={() => { this.setState({ openPopup: false }) }}
                ></div>
                <div className={`text-black-75 fixed top-0 right-0 overflow-auto h-full md:h-auto z-20 translate-x-full [&.open]:translate-x-0 [&.open]:shadow-popup md:translate-x-0 transition-transform md:relative w-[335px] md:w-1/3 lg:w-[265px] pb-10 pt-20 md:pt-0 px-5 md:px-0 bg-white-100 font-karia ${openPopup ? "open" : ""}`}>
                    <span dangerouslySetInnerHTML={{ __html: translator.t("initPage.paragraph1") }} />
                    <br />
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: translator.t("initPage.paragraph2") }} />
                    <br />
                    <br />
                    {showReferenceP2 && (
                        <small className="inline-block leading-5"><span dangerouslySetInnerHTML={{ __html: translator.t("initPage.referenceP2") }} /></small>
                    )}
                    <div className={`overflow-hidden h-0 [&.open]:h-auto transition-all  ${openText ? "open" : ""}`}>
                        <span dangerouslySetInnerHTML={{ __html: translator.t("initPage.paragraph3") }} />
                        <br />
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: translator.t("initPage.paragraph4") }} />
                        <br />
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: translator.t("initPage.paragraph5") }} /><sup>1</sup>
                        <br />
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: translator.t("initPage.paragraph6") }} />
                        <br />
                        <ul className="list-disc pl-5 mt-3 [&>li]:mt-2">
                            <li>{translator.t("initPage.bulletPoint1")}<sup>1</sup></li>
                            <li><span>{translator.t("initPage.bulletPoint2Start")}<span role="button" tabIndex="0" className="text-blue-light hover:text-blue-dark-100 hover:underline hover:underline-offset-2 transition-colors" value="ajustement" onClick={() => this.props.changeCurrentScreen({ target: { value: 'ajustement' } })}>{translator.t("initPage.bulletPoint2Link")}</span>{translator.t("initPage.bulletPoint2End")}<sup>1</sup></span></li>
                            <li>{translator.t("initPage.bulletPoint3")}<sup>1</sup></li>
                        </ul>
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: translator.t("initPage.paragraph7") }} /><sup>1</sup>
                        <br />
                        <br />
                        <small className="inline-block leading-5"><span dangerouslySetInnerHTML={{ __html: translator.t("initPage.reference") }} /></small>
                    </div>
                    <button
                        className={`block underline underline-offset-4 text-blue-light hover:text-blue-dark-100 transition-colors ${openText ? "hidden" : ""}`}
                        onClick={() => { this.setState({ openText: true }) }}
                    >{translator.t("buttons.readMore")}</button>
                    <button
                        className={`block underline underline-offset-4 text-blue-light hover:text-blue-dark-100 mt-5 transition-colors ${!openText ? "hidden" : ""}`}
                        onClick={() => { this.setState({ openText: false }) }}
                    >{translator.t("buttons.reduceText")}</button>
                    <button
                        className="py-2 px-5 text-sm rounded-full border border-black-100 font-medium mt-8 md:hidden"
                        onClick={() => { this.setState({ openPopup: false }) }}
                    >{translator.t("buttons.understood")}</button>
                    <button
                        className="absolute block md:hidden top-5 right-5 w-8 h-8"
                        onClick={() => { this.setState({ openPopup: false }) }}
                    >
                        <img src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI1LDI2Yy0wLjI1NiwwLTAuNTEyLTAuMDk4LTAuNzA3LTAuMjkzTDE2LDE3LjQxNGwtOC4yOTMsOC4yOTNjLTAuMzkxLDAuMzkxLTEuMDIzLDAuMzkxLTEuNDE0LDAJcy0wLjM5MS0xLjAyMywwLTEuNDE0TDE0LjU4NiwxNkw2LjI5Myw3LjcwN2MtMC4zOTEtMC4zOTEtMC4zOTEtMS4wMjMsMC0xLjQxNHMxLjAyMy0wLjM5MSwxLjQxNCwwTDE2LDE0LjU4Nmw4LjI5My04LjI5MwljMC4zOTEtMC4zOTEsMS4wMjMtMC4zOTEsMS40MTQsMHMwLjM5MSwxLjAyMywwLDEuNDE0TDE3LjQxNCwxNmw4LjI5Myw4LjI5M2MwLjM5MSwwLjM5MSwwLjM5MSwxLjAyMywwLDEuNDE0CUMyNS41MTIsMjUuOTAyLDI1LjI1NiwyNiwyNSwyNnoiIGZpbGw9IiMwODA4MDgiLz48L3N2Zz4=' alt="" />
                    </button>
                </div>
                <div className="w-full md:w-2/3 lg:w-auto md:max-w-auto lg:max-w-[570px] md:pl-10">
                    <Card className="bg-white-ghost !border-0 !rounded-none w-full">
                        <Card.Body>
                            <Form.Group className="bg-white-ghost py-8 px-5 text-center text-black-100 flex flex-col items-center border-b border-black-8">
                                <Form.Label className="font-medium text-xl">{translator.t("initPage.inputCard.weight.label")}</Form.Label>
                                <div className="relative mt-3 grid grid-cols-[2fr_1fr_1fr_2fr]">
                                    <div className="overflow-visible col-start-2 relative before:block before:h-[2px] before:w-[50px] before:absolute before:-bottom-[6px] before:right-0 before:bg-black-100">
                                        <Form.Control
                                            size="text"
                                            type="number"
                                            min="0"
                                            pattern="\d*"
                                            name="dog-weight"
                                            onChange={this.setUserChoices}
                                            placeholder='0'
                                            autoFocus
                                            className="appearance-none w-full text-right bg-transparent text-black-100 text-3xl font-medium outline-none placeholder:text-black-15"
                                        />
                                    </div>
                                    <span className="ml-2 text-black-100 font-normal text-left flex items-end">kg</span>
                                </div>
                                <span className="text-orange-400 text-sm mt-2">{this.state.errorMessageDogWeight}</span>
                            </Form.Group>
                            <Form.Group className="py-8 px-5 text-center flex flex-col items-center md:mx-5 bg-white-ghost md:bg-transparent relative">
                                <Form.Label className="font-medium text-xl">{translator.t("initPage.inputCard.dose.label1")}<br />{translator.t("initPage.inputCard.dose.label2")}</Form.Label>
                                <div className="w-full relative grid grid-cols-[2fr_1fr_1fr_2fr] mt-3">
                                    <div className="overflow-visible col-start-2 relative before:block before:h-[2px] before:w-[50px] before:absolute before:-bottom-[6px] before:right-0 before:bg-black-100">
                                        <Form.Control
                                            size="text"
                                            type="number"
                                            min="0"
                                            // step="1"
                                            pattern="\d*"
                                            placeholder='0'
                                            name="dose-per-kg"
                                            onBlur={this.setUserChoices}
                                            defaultValue={DOSE_INITIALE}
                                            className="appearance-none w-full bg-transparent text-right text-3xl font-medium outline-none"
                                        />
                                    </div>
                                    <span className="ml-2 text-black-100 font-normal text-left flex items-end pb-1">{translator.t("initPage.inputCard.dose.unit")}</span>
                                </div>
                                <span className="text-orange-400 text-sm mt-2">{this.state.errorMessageDosePerKg}</span>
                                <button
                                    className="flex items-center px-3 py-2 mt-7 self-start font-karia md:hidden"
                                    onClick={() => { this.setState({ openPopup: true }) }}
                                >
                                    <img className="w-[20px] mr-2" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTYsMEMyLjcsMCwwLDIuNywwLDZzMi43LDYsNiw2czYtMi43LDYtNlM5LjMsMCw2LDB6IE02LDExYy0yLjgsMC01LTIuMi01LTVzMi4yLTUsNS01czUsMi4yLDUsNVM4LjgsMTEsNiwxMXoiLz48cGF0aCBkPSJNNiA0LjhjLS4zIDAtLjUuMi0uNS41djMuNEM1LjUgOSA1LjcgOS4yIDYgOS4yYy4zIDAgLjUtLjIuNS0uNVY1LjNDNi41IDUuMSA2LjMgNC44IDYgNC44ek02IDIuOGMtLjMgMC0uNS4yLS41LjUgMCAwIDAgLjEgMCAuMSAwIC4zLjIuNS41LjVoMGMuMyAwIC41LS4yLjUtLjUgMCAwIDAtLjEgMC0uMUM2LjUgMyA2LjMgMi44IDYgMi44eiIvPjwvc3ZnPg==" alt="" />
                                    {translator.t("initPage.learnMore")}
                                </button>
                                <button
                                    className="font-karla font-medium bg-blue-light disabled:bg-black-15 disabled:text-black-30 text-white-100 transition-colors p-6 rounded-full w-full max-w-[330px] mt-9 "
                                    onClick={() => { this.setState({ activeResult: true }) }}
                                    disabled={disabledAttr}
                                >
                                    {translator.t("buttons.calculateDoseInit")}
                                </button>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                    <Card className={`bg-white-ghost pb-5 hidden [&.open]:block ${activeResult ? "open" : ""}`} >
                        <Card.Header className="bg-blue-light py-8 px-5 text-center text-white-75 flex flex-col items-center relative before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:w-0 before:h-0 before:border-[25px] before:border-t-[10px] before:border-b-0 before:border-transparent before:border-t-white-ghost">
                            <Card.Title className="font-medium">{translator.t("initPage.responseCard.doseTitle")}</Card.Title>
                            <Card.Text className="text-white-100 text-3xl font-medium mt-3">
                                {!isNaN(dogWeight)
                                    ? parseInt(dosePerKg) * parseInt(dogWeight)
                                    : "0"}{" "}
                                mg
                            </Card.Text>
                        </Card.Header>
                        <Card.Body className="md:mx-5 bg-white-100 py-9 px-5 relative before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:w-0 before:h-0 before:border-[25px] before:border-t-[10px] before:border-b-0 before:border-transparent before:border-t-blue-light">
                            <Card.Title className="font-medium text-center mb-5">{translator.t("initPage.responseCard.pillsTitle")}</Card.Title>
                            <Pillstab currentDose={currentDose} lang={this.props.lang}></Pillstab>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}

export default InitiationPage;
