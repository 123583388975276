export const DOSE_INITIALE = 5;
export const AJUSTEMENT_INITIAL = "20";

export const NBRE_COMPRIMES = [
    {
        doseJournaliere: { min: 14, max: 20 },
        soliphen15: { matin: "0,5", soir: "0,5" }
    },
    {
        doseJournaliere: { min: 20, max: 25 },
        soliphen15: { matin: "1", soir: "0,5" }
    },
    {
        doseJournaliere: { min: 25, max: 30 },
        soliphen15: { matin: "1", soir: "1" }
    },
    {
        doseJournaliere: { min: 30, max: 40 },
        soliphen15: { matin: "1,5", soir: "1" }
    },
    {
        doseJournaliere: { min: 40, max: 50 },
        soliphen15: { matin: "1,5", soir: "1,5" },
        soliphen60: { matin: "0,5", soir: "0,25" }
    },
    {
        doseJournaliere: { min: 50, max: 65 },
        soliphen15: { matin: "2", soir: "2" },
        soliphen60: { matin: "0,5", soir: "0,5" }
    },
    {
        doseJournaliere: { min: 65, max: 80 },
        soliphen60: { matin: "0,75", soir: "0,5" }
    },
    {
        doseJournaliere: { min: 80, max: 95 },
        soliphen60: { matin: "0,75", soir: "0,75" }
    },
    {
        doseJournaliere: { min: 95, max: 110 },
        soliphen60: { matin: "1", soir: "0,75" }
    },
    {
        doseJournaliere: { min: 110, max: 125 },
        soliphen60: { matin: "1", soir: "1" },
        soliphen120: { matin: "0,5", soir: "0,5" }
    },
    {
        doseJournaliere: { min: 125, max: 140 },
        soliphen60: { matin: "1,25", soir: "1" },
        soliphen120: { matin: "0,5", soir: "0,5" }
    },
    {
        doseJournaliere: { min: 140, max: 155 },
        soliphen60: { matin: "1,25", soir: "1,25" },
        soliphen120: { matin: "0,75", soir: "0,5" }
    },
    {
        doseJournaliere: { min: 155, max: 170 },
        soliphen60: { matin: "1,5", soir: "1,25" },
        soliphen120: { matin: "0,75", soir: "0,5" }
    },
    {
        doseJournaliere: { min: 170, max: 185 },
        soliphen60: { matin: "1,5", soir: "1,5" },
        soliphen120: { matin: "0,75", soir: "0,75" }
    },
    {
        doseJournaliere: { min: 185, max: 200 },
        soliphen60: { matin: "1,75", soir: "1,5" },
        soliphen120: { matin: "0,75", soir: "0,75" }
    },
    {
        doseJournaliere: { min: 200, max: 220 },
        soliphen120: { matin: "1", soir: "0,75" }
    },
    {
        doseJournaliere: { min: 220, max: 250 },
        soliphen120: { matin: "1", soir: "1" }
    },
    {
        doseJournaliere: { min: 250, max: 285 },
        soliphen120: { matin: "1,25", soir: "1" }
    },
    {
        doseJournaliere: { min: 285, max: 320 },
        soliphen120: { matin: "1,25", soir: "1,25" }
    },
    {
        doseJournaliere: { min: 320, max: 340 },
        soliphen120: { matin: "1,5", soir: "1,25" }
    },
    {
        doseJournaliere: { min: 340, max: 370 },
        soliphen120: { matin: "1,5", soir: "1,5" }
    },
    {
        doseJournaliere: { min: 370, max: 400 },
        soliphen120: { matin: "1,75", soir: "1,5" }
    },
]