import { Component } from "react";
import translator from "../../locales/I18n";

class Nav extends Component {

    componentDidUpdate() {
        translator.locale = this.props.lang;
    }

    render() {
        const { currentScreen } = this.props;

        return (
            <nav className="mb-5 md:mb-12 px-0 flex">
                <button
                    type="button"
                    className={`relative w-1/2 md:w-auto text-left font-medium text-2xl md:text-3xl rounded-l border-t border-l border-b border-r md:border-t-0 md:border-l-0 md:border-b-2 md:border-r-0 md:mr-10 px-5 pb-11 pt-5 md:px-0 md:pt-0 md:pb-6 md:border-transparent transition-colors ${currentScreen === 'initiation' ? "text-black-100 md:text-blue-light md:!border-blue-light bg-yellow-100 md:bg-transparent" : "text-black-55 md:text-black-30"}`}
                    onClick={this.props.onClick}
                    value="initiation"
                >
                    {translator.t("nav.initTreatment")}
                    <img className="absolute w-[20px] h-[15px] block md:hidden bottom-5 left-5" src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOS4yNyAxMS43NiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTkuNjM3LDExLjc1NUwwLjI2OCwxLjY4MWMtMC4zNzYtMC40MDQtMC4zNTMtMS4wMzcsMC4wNTEtMS40MTRjMC40MDUtMC4zNzUsMS4wMzgtMC4zNTQsMS40MTQsMC4wNTEJbDcuOTA0LDguNDk5bDcuOTA0LTguNDk5YzAuMzc3LTAuNDA1LDEuMDExLTAuNDI2LDEuNDEzLTAuMDUxYzAuNDA0LDAuMzc2LDAuNDI4LDEuMDA5LDAuMDUyLDEuNDE0TDkuNjM3LDExLjc1NXoiIGZpbGw9IiMwODA4MDgiLz48L3N2Zz4=' alt="" />
                </button>

                <button
                    type="button"
                    className={`relative w-1/2 md:w-auto text-left font-medium text-2xl md:text-3xl rounded-r border-t border-l border-b border-r md:border-t-0 md:border-l-0 md:border-b-2 md:border-r-0 md:mr-10 px-5 pb-11 pt-5 md:px-0 md:pt-0 md:pb-6 md:border-transparent transition-colors ${currentScreen === 'ajustement' ? "text-black-100 md:text-blue-light md:!border-blue-light bg-yellow-100 md:bg-transparent" : "text-black-55 md:text-black-30"}`}
                    onClick={this.props.onClick}
                    value="ajustement"
                >
                    {translator.t("nav.adjustTreatment")}
                    <img className="absolute w-[20px] h-[15px] block md:hidden bottom-5 left-5" src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOS4yNyAxMS43NiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTkuNjM3LDExLjc1NUwwLjI2OCwxLjY4MWMtMC4zNzYtMC40MDQtMC4zNTMtMS4wMzcsMC4wNTEtMS40MTRjMC40MDUtMC4zNzUsMS4wMzgtMC4zNTQsMS40MTQsMC4wNTEJbDcuOTA0LDguNDk5bDcuOTA0LTguNDk5YzAuMzc3LTAuNDA1LDEuMDExLTAuNDI2LDEuNDEzLTAuMDUxYzAuNDA0LDAuMzc2LDAuNDI4LDEuMDA5LDAuMDUyLDEuNDE0TDkuNjM3LDExLjc1NXoiIGZpbGw9IiMwODA4MDgiLz48L3N2Zz4=' alt="" />
                </button>
            </nav>
        );
    }
}

export default Nav;